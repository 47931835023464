
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { State, Getter, Action, Mutation, namespace } from "vuex-class";
import AreaSelect from "@/components/scope/area-select.vue";

@Component({
  components: { AreaSelect }
})
export default class SearchBar extends Vue {
  @Prop({
    default: () => []
  })
  data: any[];
  @Prop({
    default: 4
  })
  showItemNum: number;
  selfData: any[] = [];
  searchForm: any = {};
  openMore: boolean = false;
  total: number = 0;
  copyData: any[] = [];
  created() {
    if (this.showItemNum < this.data.length) {
      this.openMore = false;
    } else {
      this.openMore = true;
    }
    this.initSelfData(this.data, "init");
  }
  initSelfData(list, type?: string) {
    this.total = list.length;
    this.copyData = list.slice().filter(item => item.prop);
    let tempArr = [];
    if (type === "init") {
      if (this.openMore) {
        tempArr = list.slice();
        this.openMore = true;
      } else {
        tempArr = list.slice(0, this.showItemNum);
        this.openMore = false;
      }
    } else {
      if (this.openMore) {
        tempArr = list.slice(0, this.showItemNum);
        this.openMore = false;
      } else {
        tempArr = list.slice();
        this.openMore = true;
      }
    }
    let num = tempArr.length < 4 ? 4 - tempArr.length : 4 - (tempArr.length % 4);
    while (num > 1) {
      tempArr.push({});
      num--;
    }
    this.selfData = tempArr;
  }
  search() {
    this.$emit(
      "search",
      this.selfData.filter(item => item.prop)
    );
  }
  handleMore() {
    this.initSelfData(this.copyData);
  }
  reset() {
    this.selfData.forEach(item => {
      item.value = "";
      item.labels = "";
    });
    this.$emit("reset", this.selfData);
  }
  handleAreaChange(val, item) {
    console.log(item);
    this.$nextTick(() => {
      this.$nextTick(() => {
        const labels = (this.$refs["refAareSelect"] as any)[0].getLabels();
        item.labels = labels;
      });
    });
  }
  @Watch("data")
  watchData(val) {
    val && this.initSelfData(val, "init");
  }
}
